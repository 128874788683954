<template>
    <div id="invoice">
        <div v-if="show" :style="{'padding': '30px 15px','background-color': '#ffffff',
         'direction': (lang == 'ar'? 'rtl': 'ltr'), 'text-align': (lang == 'ar'? 'right': 'left') }">
            <h5 class="text-center">{{$t('invoice_sales_print.estimate_price')}}</h5>
            <div class="text-center" style="margin: 10px auto;width: 70px;">
                <qr-code v-if="data.estimate_code" :text="data.estimate_code" :size="size"></qr-code>
            </div>
<!--            <p class="text-center">{{data.inventory? data.inventory.name : ''}}</p>-->
<!--            <p class="text-center">{{(data.inventory && data.inventory.city)? data.inventory.city.name+',' : ''}} {{data.inventory? data.inventory.address_1 : ''}}</p>-->
<!--            <p class="text-center">{{data.inventory? data.inventory.mobile : ''}}</p>-->

            <h5>{{$t('invoice_sales_print.invoice_to')}}:</h5>
            <p>{{data.customer? data.customer.name : ''}}</p>
            <p>{{(data.customer)? data.customer.city_name : ''}} </p>
            <p>{{(data.customer)? data.customer.city_name+',' : ''}} {{(data.customer)? data.customer.postal_code : ''}}</p>

            <p><b>{{$t('invoice_sales_print.estimate_number')}}</b> <span>{{data.estimate_code}}</span></p>
            <p><b>{{$t('invoice_sales_print.estimate_date')}}</b> <span>{{data.estimate_date}}</span></p>

            <table class="table" style="margin-top: 15px">
                <thead>
                <tr style="background:#CAD3C8">
                    <th>{{$t('invoice_sales_print.item')}}</th>
                    <th>{{$t('invoice_sales_print.price')}}</th>
                    <th>{{$t('invoice_sales_print.quantity')}}</th>
                    <th>{{$t('invoice_sales_print.total')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, index) in items_list" :key="index">
                    <td style="overflow: hidden;"><span style="width:50px;display:block;word-wrap: unset;white-space: nowrap;text-overflow: ellipsis;">{{row.item? row.item.name:''}}</span></td>
                    <td>{{row.unit_price}}</td>
                    <td>{{row.qty}}</td>
                    <td>{{row.subtotal}}</td>
                </tr>
                </tbody>
            </table>


            <table class="table" style="margin-top: 15px">
                <tbody>
                <tr>
                    <th>{{$t('invoice_sales_print.total')}}</th>
                    <td>{{data.currency_name}} {{data.estimate_subtotal}}</td>
                </tr>
                <tr>
                    <th>{{$t('invoice_sales_print.value_added')}}</th>
                    <td>{{data.currency_name}} {{data.estimate_taxes_total}}</td>
                </tr>
                <tr>
                    <th>{{$t('invoice_sales_print.zero')}}</th>
                    <td>{{data.currency_name}} 0.00</td>
                </tr>
                <tr style="background:#CAD3C8">
                    <th>{{$t('invoice_sales_print.total')}}</th>
                    <td>{{data.currency_name}} {{data.estimate_total_total}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import ApiService from "@/core/services/api.service";
    import {jsPDF} from "jspdf";
    import html2canvas from "html2canvas";
    import VueQRCodeComponent from 'vue-qrcode-component';
    import jsPdfExport from "@/core/config/jsPdfExport";

    export default {
        name: "print-sales-estimate",
        components: {
            'qr-code': VueQRCodeComponent
        },
        data() {
            return {
                lang: this.$i18n.locale,
                mainRoute: 'sales/estimations',
                sales_invoice_id: this.$route.params.sales_invoice_id ? this.$route.params.sales_invoice_id : null,
              type: this.$route.params.type ? this.$route.params.type : null,
                data:{},
                items_list:[],
                size: 70,
                show: false,
            }
        },
        methods: {
            async getData() {
                await ApiService.get(`${this.mainRoute}/${this.sales_invoice_id}`).then((response) => {
                    this.data = response.data.data;
                    this.data = response.data.data;
                    this.items_list = response.data.data.items_list;

                    let _taxes =  (response.data.data.estimate_total_taxes ? (parseFloat(response.data.data.estimate_total_taxes)/100): 0);
                    let _subtotal =  (response.data.data.estimate_subtotal ? parseFloat(response.data.data.estimate_subtotal): 0);
                    let _total_taxes =  _subtotal * _taxes;
                    this.data['estimate_taxes_total'] = _total_taxes;
                    this.data['estimate_total_total'] = _total_taxes + _subtotal;
                    this.show = true;
                });
            },
            printCustomerInvoice() {
                // let element = document.getElementById('invoice');
                // let width = element.clientWidth - 110;
                // let height = element.clientHeight;
                //
                // let pdf = new jsPDF({
                //     orientation: 'p',
                //     unit: 'px',
                //     format: [width, height]
                // });
                // html2canvas(element).then(canvas => {
                //     let image = canvas.toDataURL('image/png', 1.0);
                //     pdf.addImage(image, 'PNG', 15, 15);
                //     // pdf.save('invoice'+'.pdf');
                //     pdf.autoPrint();
                //     let _url = pdf.output("bloburl");
                //     location.href = _url;
                // });
              let _id = 'invoice';
              this.exportPDF(_id);
            },
          exportPDF(_id) {
            let that = this;
            let element = document.getElementById(_id);
            let width = element.clientWidth - 205;
            let height = element.clientHeight;

            // let pdf = new jsPDF({
            //   orientation: 'p',
            //   unit: 'px',
            //   format: [width, height]
            // });
            //
            // const AmiriRegular = pdf.loadFile("https://rawcdn.githack.com/MrRio/jsPDF/cbc85b4bb66d5c1a2d50d199e8bf33836f2f12d7/test/reference/Amiri-Regular.ttf", true);
            // pdf.addFileToVFS("Amiri-Regular.ttf", AmiriRegular);
            //
            // pdf.addFont("Amiri-Regular.ttf", "Amiri", "normal");
            let pdf = jsPdfExport("p", "px", [width, height]);
            pdf.html(element, {
              html2canvas: {
                scale: 0.5,
              },
              callback: function (pdf) {
                if (that.type == 'pdf') {
                  pdf.save('invoice');
                } else {
                  pdf.autoPrint();
                  pdf.output('dataurlnewwindow');
                }

                // var iframe = document.createElement('iframe');
                // iframe.setAttribute('style', 'position:absolute;top:0;right:0;height:100%; width:600px');
                // document.body.appendChild(iframe);
                // iframe.src = pdf.output('datauristring');
              }
            });
          },
        },
        mounted() {
            let promise = this.getData();
            Promise.all([promise]).then(()=>{
                this.printCustomerInvoice();
            });

            // window.print();
        }
    }
</script>

<style>
#invoice {
  font-family: "Amiri";
  width: 412px;
}
    html, body {
        width: 312px;
        margin: 0 auto;
    }
    table,thead,tr,th,tbody,td{
        border: 1px solid #000 !important;
    }
</style>